
import MIcon from "@/components/MIcon.vue";
import { Message, ButtonTemplateMessage } from "@/entities/message";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import { Getter, State } from "@/store/helper";
import store from "@/store";
import firebase from "firebase/app";

@Options({
  components: {
    MIcon
  },
  props: {
    item: Object
  },
  emits: ["gptMessage", "openChatGPTConfigModal", "delete"]
})
export default class MSMessageItem extends Vue {
  item!: Message;
  isImageAvailable = true;
  chatGPTMode = "利用するためには、一度画面を更新してください";

  @Getter("isAdmin") isAdmin!: boolean;

  async mounted() {
    if (!this.isImageMessage) {
      this.isImageAvailable = false;
    } else {
      if (!this.item.data.messageText) {
        this.isImageAvailable = false;
        return;
      }
      const img = new Image();
      img.onerror = () => {
        this.isImageAvailable = false;
      };
      img.src = this.item.data.messageText;
    }
  }

  get isImageMessage() {
    return this.item.data.type === "image";
  }

  get templateMessage(): ButtonTemplateMessage | null {
    if (this.item.data.type !== "button_template") {
      return null;
    }
    if (!this.item.data.template) {
      return null;
    }
    return this.item.data.template;
  }

  get imageSrc(): string {
    if (this.item.data.type !== "image") {
      return "";
    }
    if (!this.messageText.includes("https://")) {
      return "";
    } else {
      return this.messageText;
    }
  }

  get videoSrc(): string {
    if (this.item.data.type !== "video") {
      return "";
    }
    if (!this.messageText.includes("https://")) {
      return "";
    } else {
      return this.messageText;
    }
  }

  get audioSrc(): string {
    if (this.item.data.type !== "audio") {
      return "";
    }
    if (!this.messageText.includes("https://")) {
      return "";
    } else {
      return this.messageText;
    }
  }

  get fileSrc(): string {
    if (this.item.data.type !== "file") {
      return "";
    }
    if (!this.messageText.includes("https://")) {
      return "";
    } else {
      return this.messageText;
    }
  }

  get icon() {
    if (this.item.data.from.type === "student") {
      return require("@/assets/student-icon.svg");
    } else if (this.item.data.from.type === "system") {
      return require("@/assets/system-icon.svg");
    } else if (this.item.data.from.type === "bot") {
      return require("@/assets/bot-icon.png");
    } else {
      return require("@/assets/tutor-icon.svg");
    }
  }

  get timeText(): string {
    const now = dayjs().locale("ja");
    const messageTime = dayjs(this.item.data.timestamp * 1000).locale("ja");
    if (now.year() !== messageTime.year()) {
      return messageTime.format("YYYY/M/D HH:mm");
    } else if (
      now.month() === messageTime.month() &&
      now.date() === messageTime.date()
    ) {
      return "今日 " + messageTime.format("HH:mm");
    } else if (
      now.month() === messageTime.month() &&
      now.date() === messageTime.date() + 1
    ) {
      return "昨日 " + messageTime.format("HH:mm");
    } else {
      return messageTime.format("M/D HH:mm");
    }
  }

  get dynamicMetaCss() {
    if (this.item.data.from.type === "student") {
      return {
        "flex-row": true
      };
    } else {
      return {
        "flex-row-reverse": true
      };
    }
  }

  get dynamicContentCss() {
    if (this.item.data.from.type === "student") {
      return {
        "mr-3": true,
        "ml-10": true
      };
    } else {
      return {
        "mr-10": true,
        "ml-3": true
      };
    }
  }

  get messageText() {
    return this.item.data.messageText!;
  }

  async askChatGPT(messageText: string) {
    if (!store.state.role) {
      return;
    }
    let prompt = "";
    const roleType = store.state.role.data.type;
    if (roleType === "tutor" && store.state.tutor) {
      prompt = store.state.tutor.main.data.prompt!.text;
    } else if (roleType === "admin" && store.state.admin) {
      prompt = store.state.admin.prompt!.text;
    }

    const callChatGPT = firebase
      .app()
      .functions("asia-northeast1")
      .httpsCallable("get_ai_message");
    store.commit(
      "START_LOADING",
      `AIが返信内容を生成中... (十数秒、時間がかかる場合があります。リロードで中断することができます。)`
    );
    const res = await callChatGPT({
      messageText,
      prompt
    });
    store.commit("END_LOADING");
    this.$emit("gptMessage", res.data.message.replace(/^\s+|\s+$/g, ""));
    if (!res.data.success) {
      throw new Error("Unexpected error occured");
    }
  }

  openChatGPTConfig() {
    this.$emit("openChatGPTConfigModal", true);
  }

  emitDeleteEvent(event: Event, message: Message) {
    // 管理人アカウントで、且つアドミンからのメッセージのみ削除できる
    if (this.isAdmin && message.data.from.type === "admin") {
      event.preventDefault();
      this.$emit("delete", message.ref.id);
    }
  }

  created(): void {
    if (!store.state.role) {
      return;
    }
    const roleType = store.state.role.data.type;
    if (roleType === "tutor" && store.state.tutor) {
      this.chatGPTMode =
        store.state.tutor.main.data.prompt!.name ??
        "利用するためには、一度画面を更新してください";
    } else if (roleType === "admin" && store.state.admin) {
      this.chatGPTMode =
        store.state.admin.prompt!.name ??
        "利用するためには、一度画面を更新してください";
    }
  }
}
